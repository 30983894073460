<template>
    <div class="bg-white">
        <app-modal-confirm
            ref="entry_modal_delete"
            :modal-header-title="$t('common.delete confirm title')"
            @confirmed="deleteEntry()"
        >
            <template v-slot:body>
                <p class="confirm-body">{{ $t("common.delete confirm") }}</p>
            </template>
        </app-modal-confirm>

        <validation-observer
            ref="form_create_entry"
            v-slot="{ passes, valid, validated }"
        >
            <form class="from-create-answer form-horizontal" v-on:submit.prevent="passes(createOrUpdate)">
                <div class="card card-body">
                    <div v-if="idEditing">
                        <div class="form-group row">
                        <label class="col-xl-1 col-lg-2 col-sm-2">{{ $t("common.id") }}</label>
                        <div class="col-xl-5 col-lg-6 col-sm-6 p-t-8"> {{ entry.id }}</div>
                        </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-xl-1 col-lg-2 col-sm-2">{{ $t("answer.name_big") }} <span class="app-require">＊</span></label>
                      <div class="col-xl-5 col-lg-6 col-sm-6">
                        <app-select
                          name="big_category_id"
                          rules="required"
                          :options-data="meta.big_category"
                          v-model="entry.big_category_id"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-xl-1 col-lg-2 col-sm-2">{{ $t("answer.name_medium") }} <span class="app-require">＊</span></label>
                      <div class="col-xl-5 col-lg-6 col-sm-6">
                        <app-select
                          name="medium_category_id"
                          rules="required"
                          :options-data="meta.medium_category"
                          v-model="entry.medium_category_id"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-xl-1 col-lg-2 col-sm-2">{{ $t("answer.name_small") }} <span class="app-require">＊</span></label>
                      <div class="col-xl-5 col-lg-6 col-sm-6">
                        <app-select
                          name="category_id"
                          rules="required"
                          :options-data="meta.small_category"
                          v-model="entry.category_id"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-xl-1 col-lg-2 col-sm-2">{{ $t("common.name") }} <span class="app-require">＊</span></label>
                        <div class="col-xl-5 col-lg-6 col-sm-6">
                        <app-input
                            name="name"
                            type="string:40"
                            rules="required"
                            v-model="entry.title"
                        />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-xl-1 col-lg-2 col-sm-2">{{ $t("common.note") }}</label>
                        <div class="col-xl-5 col-lg-6 col-sm-6">
                        <app-text
                            name="note"
                            type="string1024"
                            v-model="entry.note"
                        />
                        </div>
                    </div>
                    <validation-provider
                        name="summernote"
                        rules="required"
                        v-slot="{ errors, invalid, validated }"
                    >
                        <div class="form-group">
                            <div class="row pl-2 justify-content-between">
                                <div class="col-xl-8 col-lg-7 col-md-7 col-sm-8 pl-0">
                                    <label>{{ $t("answer.contents") }} <span class="app-require">＊</span></label>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-4 col-sm-3 pl-0">
                                    <label style="line-height: 26px;">{{ $t("answer.preview") }}</label>
                                </div>
                            </div>
                            <div class="row pl-2 justify-content-between">
                                <div class="col-xl-8 col-lg-7 col-md-7 col-sm-8 pl-0">
                                    <app-summernote v-if="loading" max-length="5120"
                                        @input="entry.contents = $event" v-model="entry.contents" :val="entry.contents">
                                    </app-summernote>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-4 col-sm-3 pl-0">
                                    <div class="html-preview">
                                        <div v-html="entry.contents"></div>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 pl-0">
                                    <label v-if="invalid && validated" class="error">
                                        {{ errors[0] }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </validation-provider>

                    <div class="card-footer row justify-content-end gr-button">
                        <button type="button" :disabled="!valid && validated" class="btn btn-default m-r-10" @click="handlerCancelButton">
                        {{ $t('common.cancel') }}
                        </button>
                        <button type="button" class="btn btn btn-danger m-r-10" :disabled="!valid && validated" @click="handleDeleteConfirm" v-if="idEditing">
                            {{ $t('common.delete') }}
                        </button>
                        <button :disabled="!valid && validated" class="btn btn-primary" type="submit">
                            {{idEditing ?  $t('common.save') :  $t('common.create')}}
                        </button>
                    </div>
                </div>
            </form>
        </validation-observer>
    </div>
</template>
<script>
import {createHmac} from "crypto";
import {__noticeWarning} from "../../../utils";
import AppSummerNote from "../../../components/forms/AppSummernote";
import {ROUTES} from "@constants";
export default {
    name: "FormPage",
    components: {
        AppSummerNote
    },
    data() {
        const id = this.$route.params.id
        return {
            entry: {
                contents: "",
                apiKeys: [],
                big_category_id : "",
                medium_category_id : ""
            },
            apiKeyId: null,
            meta: {
                big_category: [],
                medium_category: [],
                small_category: []
            },
            idEditing: id,
            loading : true,
            isLineLink: '',
        }
    },

    watch : {
        'entry.big_category_id': async function (value, oldValue) {
            if (value) {
              await this.getMetaData({big_category_id: value, medium_category_id: this.entry.medium_category_id})
            } else {
                this.meta.medium_category = [{
                    id: "",
                    name: this.$t('common.choose'),
                }]
            }
        },

        'entry.medium_category_id': async function(value, oldValue) {
            if (value) {
                await this.getMetaData({big_category_id: this.entry.big_category_id, medium_category_id: value})
            } else {
                this.meta.small_category = [{
                    id: "",
                    name: this.$t('common.choose'),
                }]
            }
        },
    },

    mounted:async  function() {
      if (!this.idEditing) {
        await this.getMetaData({});
      }
    },
    async created() {
      if (this.idEditing) {
        this.loading = false;
        await this.$request.get(this.ENDPOINT.ANSWER_EDIT(this.idEditing)).then(res => {
          if (res.hasErrors()) {
            this.redirectDefaultValue()
          }
          this.entry = res.data.data
          this.loading = true;
        })
      }
    },
    methods: {

        getMetaData(params) {

            this.$request.get(this.ENDPOINT.UI_HELPER_CHAT_BOT_CATEGORY, params).then(res => {
                this.meta = {
                    big_category: res.data.big_category,
                    medium_category: res.data.medium_category,
                    small_category: res.data.small_category
                }
                this.meta.big_category.unshift({
                    id: "",
                    name: this.$t('common.choose'),
                })
                this.meta.medium_category.unshift({
                    id: "",
                    name: this.$t('common.choose'),
                })
                this.meta.small_category.unshift({
                    id: "",
                    name: this.$t('common.choose'),
                })
            })

        },

        handlerCancelButton() {
          if(this.$store.state.setting.prevRouter.name === ROUTES.ADMIN.ANSWER_LIST) {
            this.__redirectBack()
          } else {
            this.redirectDefaultValue()
          }
        },
        redirectBack() {
            this.$router.go(-1);
        },
        async createOrUpdate() {
            let res = null;
            let msg = "";
            let params = _.cloneDeep(this.entry);
            if (this.idEditing) {
                res = await this.$request.patch(this.ENDPOINT.ANSWER_EDIT(this.idEditing), params);
                msg = this.$t("common.msg update ok")
            } else {
                res = await this.$request.post(this.ENDPOINT.ANSWER_CREATE, params);
                msg = this.$t("common.msg create ok")
            }
            if (res.hasErrors()) {
                this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
            } else {
                this.__noticeSuccess(msg);
                await this.redirectDefaultValue()
            }
        },
        handleDeleteConfirm() {
            this.$refs.entry_modal_delete.show();
        },
        async deleteEntry() {
            const res = await this.$request.delete(this.ENDPOINT.ANSWER_DELETE(this.idEditing));
            if (!res.hasErrors()) {
                this.__noticeSuccess(this.$t("common.msg delete ok"));
                await this.redirectDefaultValue();
            } else {
                this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
              this.$refs.entry_modal_delete.hide();
            }
        },
        async redirectDefaultValue() {
            await this.$router.push({
                name: this.ROUTES.ADMIN.ANSWER_LIST, query: {
                    'filters.big_category_id.equal': 'all',
                }
            });
        },
    },
}
</script>
